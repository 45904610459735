.centered-container {
    max-width: 800px; 
    margin: auto;
    padding: 20px;
    background-color: #f3f3f3;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    border: 1px solid #ddd;
  }
  

  .vimeo-video-wrapper {
    position: relative;
    width: 100%; // Full width of the container
    padding-bottom: 56.25%; // Aspect ratio for 16:9 videos
    height: 0; 
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }