@import "https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,600;9..40,700&family=Nunito+Sans:wght@400;500;600;700&family=Parisienne&display=swap";

.certificate-outer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #375376;
  position: relative;

  width: 995px;
  height: 705px;
  padding: 10px;
  box-sizing: border-box;
  @media (max-width: 900px) {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .inner-container {
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    border-radius: 5px;
    // width: 800px;
    // height: 600px;
    box-sizing: border-box;
    position: absolute;
    top: 57px;
    left: 0;
    width: 100%;
    height: 570px;

    .header {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      .school-icon {
        font-size: 64px;
        margin-right: 16px;
      }
    }

    .name {
      font-weight: bold;
      font-size: 32px;
      text-align: center;
      margin-bottom: 16px;
    }

    .user-name {
      font-family: "Parisienne", "serif";
      font-size: 50px;
      color: #eeb741;
    }

    .license-number {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    }

    .divider {
      width: 100%;
      background-color: #000;
      margin: 16px 0;
    }

    .subtitle {
      font-size: 20px;
      margin-bottom: 16px;
      text-align: center;
    }

    .course {
      font-weight: bold;
      font-size: 19px;
      color: #375376;
      margin-bottom: 15px;
      text-align: center;
    }

    .sub-course {
      font-size: 14px;
      color: #375376;
      margin-bottom: 16px;
      text-align: center;
    }

    .date {
      font-size: 18px;
      text-align: center;
    }

    .instructor {
      font-size: 20px;
      margin-bottom: 16px;
      text-align: center;
    }
    .accreditation-box {
      background-color: #ffffff;
      border: 1px solid #375376;
      padding: 10px;
      width: 300px;
      font-size: 12px;
      color: #375376;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }

    .accreditation-text {
      margin-bottom: 5px;
    }
  }
}

.medessist-container {
  margin: auto;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  font-size: smaller;
}

.medessist-logo {
  max-width: 50px;
  max-height: 33px;
  margin-right: 8px;
}
