$blue: #2c7be5;
$indigo: #727cf5;
$purple: #6b5eae;
$pink: #ff679b;
$red: #e63757;
$orange: #fd7e14;
$yellow: #f6c343;
$green: #00d97e;
$teal: #02a8b5;
$cyan: #39afd1;
$white: #ffffff;
$gray: #95aac9;
$gray-dark: #3b506c;
$primary: #2c7be5;
$secondary: #6e84a3;
$success: #00d97e;
$info: #39afd1;
$warning: #f6c343;
$danger: #e63757;
$light: #edf2f9;
$dark: #12263f;
$white: #ffffff;

//redesign colours
$color-silver: #cccccc;
$color-primary_yellow: #ffd85f;
$color-secondary_offwhite: #ffffff;
$color-teal: #5ed6d0;
$color-alt-gray: #e3e3e3;

$color-funny_number_grey: #696969;

$main_1: #01cbcb;
$main_2: #ffcc33;
$main_3: #ecfcfc;

$notepad-blue: #d3eef1;
$notepad-pink: #ffd7d7;
$notepad-yellow: #fbedc4;

$box-shadow-lift: 0 1rem 2.5rem fade-out(black, 0.9),
  0 0.5rem 1rem -0.75rem fade-out(black, 0.9) !default;
