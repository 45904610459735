@import "./common/style/variables";
@import "https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;500;600;700&display=swap";

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  font-family: "Nunito Sans", sans-serif;
  color: #333;
  background-color: #f9f9f9;
}

html {
  background: #f9f9f9;
}

.main-wrapper {
  padding: 1em;
  width: 100%;
}

//reusable buttons
.new-button-style {
  background: #efefef;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  padding: 10px 1em 10px 1em;
  transition: all 0.4s;
  border-radius: 20px;
  border: none;
  min-width: 120px;
  font-weight: 700;
  /* transitions */
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  &:hover {
    //filter: brightness(115%);
    background: $color-teal;
  }
}

.primary {
  background: $color-primary_yellow;
  &:hover {
    filter: brightness(90%);
    background: $color-primary_yellow;
  }
  &:disabled {
    background: #fff5d8;
    color: #e1d1a1;
    &:hover {
      filter: none;
      cursor: default;
    }
  }
}

.teal {
  background: $color-teal;
}

.teal-hover {
  &:hover {
    filter: none;
    background: $color-teal;
    color: white;
  }
}

.yellow-hover {
  &:hover {
    filter: brightness(90%);
    background: $color-primary_yellow;
  }
}

.secondary {
  background: $color-silver;
}

.secondary-two {
  background: $color-secondary_offwhite;
}

.secondary-settings {
  background: #999797;
  font-weight: 600;
  &:disabled {
    background: #c6c3c3;
  }

  &:hover:enabled {
    filter: none;
    color: white;
  }
}

.secondary-outline {
  background: transparent;
  border: 1px solid $color-funny_number_grey;
  &:hover {
    background: $color-funny_number_grey;
    filter: brightness(100%);
    color: white;
  }
}

.danger-red {
  background: #ff8b7e;
  color: #820a0a;
  &:hover {
    filter: brightness(90%);
    background: #ff8b7e;
  }
}

.light-blue {
  background: #daf4f3;
  &:hover {
    background: #9cd6d3;
  }
}

.disabled {
  &:hover {
    filter: none;
  }
  cursor: default !important;
}

.app-wrapper {
  display: flex;
}

@media screen and (max-width: 1200px) {
  .app-wrapper {
    display: block;
  }

  .main-wrapper {
    padding: 0;
  }
}
