.course-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 2px 20px 10px;
  
    .course-name {
      color: #333;
    }
  
    .instructor-container {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
  
      .instructor-name {
        color: #666;
				font-size: 20px;
        cursor: pointer;
  
        &:hover {
          color: #333;
        }
      }
    }
  }

.back-to-courses {
	display: flex;
	align-items: center;
	margin-bottom: 1rem;
	color: gray;
	border: none;
	font-size: 18px;
	min-width: fit-content;
	background-color: transparent;
	cursor: pointer;

	&:hover {
		color: #666;
	}
}

.course-header-title {
	display: flex;
	flex-direction: column;
	align-items: start;
	text-align: left;
}

@media (max-width: 650px) {
  .course-header-container {
    flex-direction: column;
		align-items: start;
  }

	.back-to-courses {
		width: 98%;
		display: flex;
		justify-content: center;
	}
}

  