.course-card {
  cursor: pointer;

  &__image-wrapper {
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__title {
    font-size: 1rem;
    margin-bottom: 0.2rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__description {
    font-size: 0.85rem;
    margin-bottom: 0.2rem;
    overflow: hidden;
    color: gray;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__category {
    font-size: 0.8rem;
    color: #757575;
  }

  &__avatar {
    width: 30px;
    height: 30px;
  }

  &__instructor-container {
    display: flex;
    align-items: center;
    margin-top: auto;
  }

  &__instructor-name {
    font-weight: bold;
    font-size: 0.9rem;
  }

  &__rating-banner {
    background: #f4a261;
    color: white;
    padding: 5px;
    text-align: center;
    border-radius: 5px;
    font-weight: bold;
    font-size: 0.8rem;
  }
}

.course-navbar {
  // background-color: #f0f0f0;
  padding: 10px;
  cursor: pointer;
}

.MuiTabs-flexContainer {
  display: flex;
  justify-content: space-between;
}

.Mui-selected .MuiTab-label {
  color: #009688;
}

.course-navbar .MuiTabs-indicator {
  background-color: #009688;
}
