.link {
  display: flex;
  justify-content: center;
  color: inherit;
  text-decoration: inherit;
  margin-bottom: 1rem;
  transition: color 0.3s;
}

.link-hovered {
  display: flex;
  justify-content: center;
  color: #5ed6d0; /* Change the color to your desired hover color */
  text-decoration: inherit;
  margin-bottom: 1rem;
  transition: color 0.3s;
}
.span {
  display: flex;
  justify-content: center;
  color: inherit;
  text-decoration: inherit;

  transition: color 0.3s;
}

.span-hovered {
  display: flex;
  justify-content: center;
  color: #5ed6d0; /* Change the color to your desired hover color */
  text-decoration: inherit;

  transition: color 0.3s;
}

.podcast-subscribe-button {
  text-align: center;
  background-color: rgb(255 217 93);
  color: #474747;
  font-weight: bold;
  padding: 15px;
  border-radius: 20px;
  transition: 0.2s all ease;
  &:hover {
    cursor: pointer;
    background: #ffc900;
  }
}

.platform-button {
  display: flex;
  align-items: center;
  border: none;
  border-radius: 20px;
  padding: 5px 10px 5px 10px;
  background: #fff3ba;
  width: 165px;

  &:hover {
    background: white;
  }
}
