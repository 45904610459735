.resource-list-mobile,
.email-text-buttons-mobile {
  display: none;
}

.resource-list-item {
  flex-grow: 100;
  padding: 5px 0rem;
}
@media (max-width: 1200px) {
  .resource-side-bar {
    display: none;
  }
  .resource-list-mobile {
    display: flex;
    justify-content: center;
  }
  .email-text-buttons {
    display: none;
  }
  .email-text-buttons-mobile {
    display: block;
  }
}
