// color variables (adjust?)
$teal-dark: #00796b;
$grey-dark: #606060;
$teal: #e0f7fa;
$grey: #f5f5f5;
$primary-color: #1976d2;
$secondary-color: #f5f5f5;
$border-color: #e0e0e0;

.forum-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.question-content {
  /* Light blue background */
  background-color: $teal;
  border-radius: 8px;

  padding: 16px;
  margin: 8px 10px 2px 8px;

  .question-author {
    color: $teal-dark;
    font-weight: 800;
    font-size: 1.1em;
  }

  .mui-icon {
    color: $teal-dark; // apply the same darker teal color to MUI icons
    opacity: 0.2; // Set default opacity
    transition: opacity 0.2s ease-in-out; // Smooth transition

    &:hover {
      opacity: 0.8; // Full opacity on hover
    }
  }
}

.reply-content {
  /* Light grey background */
  background-color: $grey;
  border-radius: 8px;
  padding: 10px;

  &.instructor-reply {
    background-color: #fff8e1; // Light yellow/goldish for instructors
  }

  .reply-author {
    color: $grey-dark;
    font-weight: 800;
    font-size: 1.1em;

    &.instructor {
      color: #c5a600; // Golden color for instructors
    }
  }

  .mui-icon {
    color: $grey-dark; // apply the same darker teal color to MUI icons
    opacity: 0.2; // Set default opacity
    transition: opacity 0.2s ease-in-out; // Smooth transition

    &:hover {
      opacity: 0.8;
    } // Full opacity on hover
  }
}

.ask-question-textbox {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 6px;
  width: 100%;
  font-size: 1rem;
  outline: none;
  position: relative;
  margin: 8px 10px 2px 8px;

  .MuiOutlinedInput-root {
    padding-right: 10px; /* enough space for the icon */
    /* removign the border */
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }

  .MuiInputAdornment-root {
    position: absolute;
    right: 20px;
  }
}

.ask-question-section {
  background-color: $grey;
  border-radius: 8px;
  padding: 20px;
}

.forum-accordion {
  box-shadow: none !important;
  margin: 8px;

  .MuiPaper-root {
    border: none;
  }

  .MuiAccordionSummary-root {
    background-color: white;
    padding: 16px 24px;
    border-radius: 8px;
    .MuiAccordionSummary-expandIconWrapper {
      svg {
        font-size: 3rem; // making the down arrow for expanding muiaccordion bigger
      }
    }
  }

  .MuiAccordionSummary-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .MuiAccordionDetails-root {
    background-color: #fff;
    padding: 20px 24px;
  }
}

// Ask question section
.question-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px; // space between the links
  padding: 4px;
  background-color: $teal; // have background color to match the question box
  border-radius: 1px;

  a {
    color: $teal-dark;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    font-weight: 700;
    font-size: 12px;

    .MuiSvgIcon-root {
      font-size: 2em;
      margin-left: 4px;
      vertical-align: middle;
    }

    &:hover {
      text-decoration: underline;
    }
  }
  /* vertical line between the two links */
  a:first-of-type::after {
    content: "";
    display: inline-block;
    width: 2px;
    height: 12px;
    background-color: $teal-dark;
    margin: 0 16px;
    transform: translateY(2px);
  }
}

//eg profession and province displayed in lighter smaller font
.author-details {
  font-size: 0.875em;
  color: #888;
  margin-left: 8px;
}
