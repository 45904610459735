.delete-icon-link {
  cursor: pointer;
  color: #5ed6d0;
  transition: all 0.5s ease !important;

  &:hover {
    color: gray;
  }
}

.user-profile-card {
  max-width: 400px;
  margin: auto;
  justify-content: center;
  // margin-top: 5rem;
  padding: 3rem;
  border-radius: 10px;
  background-color: #f5f5f5;
}

.admin-card {
  margin: auto;
  justify-content: center;
  // margin-top: 5rem;
  padding: 3rem;
  border-radius: 10px;
}

.outer-avatar-box {
  display: flex;
  flex-direction: column;
}

.inner-avatar-box {
  display: flex;
  margin-right: 0.5rem;
}

/* for the buttons used on profile page to toggle courses */
.courseToggleButtonContainer {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.courseToggleButton {
  border-radius: 0.5rem;
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s;
  font-weight: 400;
  &:focus {
    outline: none;
  }
}

.completedCourseButton {
  background-color: #7fd3cf;
  color: white;
}

.inProgressCourseButton {
  background-color: #fff1c0;
  color: black;
}

.link-pharmacy-button {
  background-color: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.pharmacy-list-item {
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 1rem;
  padding: 10px;
  display: flex;
  align-items: center;
}

.no-pharmacies-linked {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  align-items: center;
  min-height: 4em;
  color: gray;
}

.name-field-wrapper {
  display: flex;
  flex-direction: column;
  padding: 1em;
}

.user-courses-pharmacies {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
}

.linked-pharmacies {
  display: flex;
  flex-direction: row;
  border: 0px;
  border-radius: 1rem;
  box-shadow: none;
}

@media screen and (max-width: 720px) {
  .inner-avatar-box {
    flex-direction: column;
  }

  .user-profile-card {
    margin: 0;
    padding: 1em;
  }

  .name-field-wrapper {
    padding: 0;
  }

  .user-courses-pharmacies {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .header-section {
    flex-direction: column;
  }

  .linked-pharmacies {
    width: 100%;
    padding: 1em;
  }

  .profile-header-summary {
    flex-direction: column !important;
    text-align: center !important;
  }

  .account-settings-btn {
    position: unset !important;
  }
}
