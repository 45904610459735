.search-bar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fffafa;
  border-radius: 24px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  width: 60%;
  max-width: 400px;
  padding: 0.25rem 1rem;

  & svg {
    margin-right: 0.5rem;
    color: grey;
  }

  & input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 0.9rem;
    font-family: inherit;
  }
}

//for screens up to 400 pixels wide (mobile phones)
@media (max-width: 750px) {
	.search-bar {
		width: 100%;

	}
}


