.quiz-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.parent-container {
  position: relative;
}

.review-container {
  max-height: 400px;
  overflow-y: auto;
  text-align: left;
}

.review-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

.review-list::-webkit-scrollbar {
  width: 7px;
}

.review-list::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.review-list {
  mask-image: linear-gradient(to bottom, black calc(100% - 5px), transparent 100%);
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.scroll-indicator {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  animation: pulse 2s infinite;
  border: 2px solid rgba(255, 255, 255, 0.7); 
  border-radius: 50%; 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); 
  padding: 2px; 
}
