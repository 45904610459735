.course-container {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
}

.vimeo-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.vimeo-embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.card-container {
  margin: 20px 0;
  display: inline-block;

  .button-container {
    display: flex;
    justify-content: center;
  }
}

.course-title {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin: 1.25rem 0;
  letter-spacing: 0.05em;
}

// Set the default size of the quiz container
.quiz-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  // height: 400px;
  margin: 20px auto;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
}

.audio-container {
  border: 1px solid gray;
  width: 50%;
  height: 100%;
  margin-top: 1.5rem;
}

.enroll-button-container {
  display: flex;
  justify-content: space-around;
  margin-top: 1.5rem;
}

.enroll-button {
  min-width: 40%;
}

.course-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.course-navbar-item {
  margin-right: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
}

.course-navbar-item.active {
  background-color: #ccc;
}

.card-container-border {
  border-radius: 15px !important;
  padding: 25px 0 15px 0;
}

.audio-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  height: fit-content !important;
}

.listen-audio-button {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.course-info-container {
  padding: 20px;
  margin: 1.5rem 0;
}

.course-info-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.take-quiz-button {
  height: fit-content;
  padding: 10px 20px !important;
  margin-right: 1rem;
  display: flex !important;
  align-items: center;
}

.certificate-header {
  color: rgb(92, 186, 92);
  font-weight: bold !important;
  margin-bottom: 1rem !important;
}

.unlocking-container {
  width: 35%;
}

.filtering-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

.course-category-filter {
  display: flex;
  align-items: center;
}


//for screens up to 767 pixels wide (typically mobile devices in portrait mode)
@media (max-width: 767px) {
  .quiz-container {
    width: 90%;
    // height: 80%;
  }

  .course-info-header {
    flex-direction: column-reverse;
    align-items: center;
  }

  .take-quiz-button {
    margin-right: 0;
    margin-top: 2rem;
  }

  .unlocking-container {
    margin-top: 2rem;
  }
}

//for screens between 768 and 991 pixels wide (typically tablets in portrait mode or smaller laptop screens)
@media (min-width: 768px) and (max-width: 991px) {
  .quiz-container {
    width: 70%;
    // height: 60%;
  }
}

//for screens between 992 and 1199 pixels wide (typically larger laptop screens or smaller desktop screens)
@media (min-width: 992px) and (max-width: 1199px) {
  .quiz-container {
    width: 60%;
    // height: 50%;
  }
}

//for screens 1200 pixels wide or larger (typically larger desktop screens)
@media (min-width: 1200px) {
  .quiz-container {
    width: 50%;
  }
}

//for screens up to 400 pixels wide (mobile phones)
@media (max-width: 400px) {
  .audio-container {
    width: 100%;
  }

  .enroll-button-container {
    flex-direction: column;
  }

  .enroll-button {
    margin-bottom: 1rem;
  }

  .audio-section {
    margin-top: 1rem;
  }

  .card-container-border {
    padding: 0 0 15px 0;
  }

  .filtering-container {
    flex-direction: column;
    width: 100%;
    gap: 15px;
  }
  .course-category-filter {
    width: 100%;
  }
}



@media (max-width: 750px) {
  .filtering-container {
    flex-direction: column;
    width: 100%;
    gap: 15px;
  }
  .course-category-filter {
    width: 100%;
  }
}
