.typewriter {
  opacity: 0;
  word-wrap: normal;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  animation: fadeIn 1s forwards;
  animation-delay: calc(
    var(--char-index) * 0.01s
  ); /* Adjust the delay as needed */
}

.fade-in {
  animation: fadeIn 1.5s forwards;
}

.ellipsis-anim span {
  opacity: 0;
  -webkit-animation: ellipsis-dot 1s infinite;
  animation: ellipsis-dot 1s infinite;
}

.ellipsis-anim span:nth-child(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.ellipsis-anim span:nth-child(2) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.ellipsis-anim span:nth-child(3) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@-webkit-keyframes ellipsis-dot {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes ellipsis-dot {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.ai-button {
  background-color: #3ac0c3;
  color: white;
  text-decoration: none;
  border-radius: 60px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  width: auto;
  max-width: 50px;
  -webkit-transition: max-width 0.5s;
  transition: max-width 0.5s;
  border: none;
  position: fixed;
  bottom: 40px;
  right: 250px;
}
.ai-content {
  margin-left: 30px;
  width: 85%;
}

.ai-container {
  width: 800px;
  height: 800px;
}
ai-message-output {
  margin-top: 2em;
}

.marketing-intro {
  text-align: left;
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ai-button:hover {
  max-width: 300px;
}

.icon {
  font-size: 16px;
  margin-right: 14px;
  padding: 0px 7px;
  display: flex;
  align-items: center;
}

.text {
  white-space: nowrap;
  padding-right: 15px;
  font-weight: bold;
  font-size: 16px;
}

.chat-history-block {
  &:hover {
    background: #fff1c0;
    transition: all 0.2s ease;
  }
}

.marketing-description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
}
.marketing-description h5 {
  margin: 0;
  font-size: 16px;
  color: rgb(106, 106, 106);
  font-weight: 500;
}
.ai-container p {
  margin-top: 0;
}

.AI-response li {
  list-style-type: disc;
}
.AI-response td {
  border: grey 1px solid;
}
.AI-response th {
  border: grey 1px solid;
}
.AI-response table {
  border-spacing: 0px;
}

.ai-sources li {
  list-style-type: none;
}

.feedback-ai {
  border: 1px gray solid;
  border-radius: 15px;
  padding: 1em 0.5em;
  width: fit-content;
  margin: 1em 0 1em auto;
}
@media only screen and (max-width: 600px) {
  .ai-button {
    right: 50px;
  }
	.ai-handout-button {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .ai-container {
    width: auto;
    height: auto;
  }
  .ai-content {
    margin-left: 0px;
    width: 100%;
    overflow-x: scroll;
  }

  .marketing-intro {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .AI-response ul {
    padding: 1em;
  }
}
