.btn {
  padding: 2em;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-radius: 20px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 0em 0em; /* Initial size for transition */
  transition: background-size 0.3s ease-in;

  &:hover {
    background-size: 200% 200%;
  }
}

.pink {
  background-image: radial-gradient(
    circle at center,
    #fac2c2 50%,
    transparent 50%
  );
}

.blue-circle {
  background-image: radial-gradient(
    circle at center,
    #c2eefa 50%,
    transparent 50%
  );
}

.yellow-circle {
  background-image: radial-gradient(
    circle at center,
    #faf1c2 50%,
    transparent 50%
  );
}

.home-banner {
  box-sizing: border-box;
  flex-shrink: 0;
  background: #daf4f3;
  min-height: 400px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 2em 2em 0 2em;
}

.professionals {
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  bottom: 0;
}

.professional-img {
  width: 200px;
}
.solo-img {
  width: 300px;
}

.ai-button-wrapper {
  position: absolute;
  right: 2em;
  bottom: 2em;
  font-weight: bold;
}

.ai-buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

@media screen and (max-width: 720px) {
  .btn {
    padding: 1em;
  }
  .home-banner {
    text-align: center;
    justify-content: center;
    padding: 0;
    h1 {
      font-size: 20px;
      padding: 2em 2em 0 2em;
    }

    p {
      padding: 1em 1em 0 1em;
    }
  }

  .professionals {
    margin: auto;
  }
  .professional-img {
    width: 130px;
  }
  .solo-img {
    width: 200px;
  }
  .ai-button-wrapper {
    position: unset;
  }
  .ai-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

@media screen and (max-width: 400px) {
  .professional-img {
    width: 100px;
  }
}
