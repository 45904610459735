.account-deleted {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    text-align: center;


  
    &__icon {
      margin-top: 10rem;
      color: #4caf50; 
    }
  
    &__title {
      margin: 20px 0;
      color: #3f3f3f;
      font-size: 2rem;
    }
  
    &__message {
      color: #6f6f6f;
    }
  }
  