.loader {
  width: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.blog-frame {
  height: 100vh;
  width: 90%;
  border: none;
  border-radius: 20px;
  margin: auto;
}
.blog-banner {
  transition: all 0.5s ease;
  opacity: 1;
  width: 82.3%;
  border-radius: 20px;
  margin-top: 2em;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.blog-button {
  position: absolute;
  bottom: 2em;
  min-width: 0px;
}

// adjustments for medium screens (tablets, small laptops)
@media (max-width: 1266px) {
  .blog-banner {
    height: auto;
    max-width: 100%;
  }
  
  .blog-button {
    font-size: 12px;
    padding: 10px;
    bottom: 10px;
  }
}

// adjust for small screens (larger phones)
@media (max-width: 768px) {
  .blog-button {
    font-size: 10px; 
    padding: 5px;
    bottom: 6px; 
  }
}

// adjust for extra small screens ( small phones)
@media (max-width: 480px) {
  .blog-button {
    font-size: 5px; 
    padding: 4px; 
    bottom: 5px; 
  }
}
