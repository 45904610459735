.emailAndTextContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: white;
  transform: translate(-50%, -50%);
  border: 2px solid #000;
  text-align: center;
  border-radius: 20px;
  width: 50%;
  font-family: Nunito Sans;
  padding: 1rem;
}
.email-send-info {
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-template-rows: 1fr 1fr;
  margin: 0 2rem;
}

@media (max-width: 800px) {
  .emailAndTextContainer {
    position: absolute;
    background-color: #ffffff;
    border: 2px solid #000;
    text-align: center;
    border-radius: 20px;
    width: 90%;
    padding: 1rem;
  }
  .email-send-info {
    margin: 0;
  }
}

.ql-container {
  font-size: 16px !important;
  font-family: Nunito Sans !important;
  border-end-start-radius: 20px;
  border-end-end-radius: 20px;
}
.ql-toolbar.ql-snow {
  border-start-start-radius: 20px;
  border-start-end-radius: 20px;
}
