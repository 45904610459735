.toolbar {
  display: flex;
  align-items: center;
}

.education-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  color: black;
  //flex: 1;
}

.education-header-content {
  display: flex;
  align-items: center;
}

.logo-container {
  display: flex;
  align-items: center;
  margin: 2em;
  justify-content: center;
}

.logo {
  height: 50px;
}

.logo-link {
  text-decoration: none;
  color: #fff;
}

@media only screen and (max-width: 500px) {
  .logged-in-logo {
    height: 35px;
  }

  .education-title {
    font-size: 18px;
  }

  .logout-button {
    padding-right: 0 !important;
  }

  .logged-in-header {
    padding-left: 5px;
  }

  .logged-in-icon {
    visibility: hidden;
  }
}
