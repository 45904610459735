.not-found-title {
  color: #61cccc;
  font-weight: bold;
  margin-top: 1rem;
  font-size: 60px;
  margin-bottom: 0px;
}


@media screen and (max-width: 500px) {
  .not-found-image {
    width: 80vw;
  }
}
